// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// // Material Kit 2 PRO React components
// import MKTypography from "components/MKTypography";

// // Images
// import logoCT from "assets/images/logo-ct-dark.png";

//const date = new Date().getFullYear();
import Home from "layouts/pages/home";
import About from "layouts/pages/about";
export default {
  // brand: {
  //   name: "Material Kit 2 PRO",
  //   image: logoCT,
  //   route: "/",
  // },
  // socials: [
  //   {
  //     icon: <FacebookIcon />,
  //     link: "https://www.facebook.com/CreativeTim/",
  //   },
  //   {
  //     icon: <TwitterIcon />,
  //     link: "https://twitter.com/creativetim",
  //   },
  //   {
  //     icon: <GitHubIcon />,
  //     link: "https://github.com/creativetimofficial",
  //   },
  //   {
  //     icon: <YouTubeIcon />,
  //     link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
  //   },
  // ],
  menus: {
    home: {
      name: "Home",
      route: "#/home",
      component: <Home />,
    },
    about: {
      name: "About",
      route: "#/about",
      component: <About />,
    },
  },
  // copyright: (
  //   <MKTypography variant="button" fontWeight="regular">
  //     All rights reserved. Copyright &copy; {date} Material Kit by{" "}
  //     <MKTypography
  //       component="a"
  //       href="https://www.creative-tim.com"
  //       target="_blank"
  //       rel="noreferrer"
  //       variant="button"
  //       fontWeight="regular"
  //     >
  //       Creative Tim
  //     </MKTypography>
  //     .
  //   </MKTypography>
  // ),
};

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Content from "Content/about.json";

function Training() {
  return (
    <MKBox>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid item xs={12} md={9} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="dark"
              p={1.5}
              mt={-3}
              textAlign="center"
            >
              <MKTypography variant="h3" color="white">
                {Content.trainingTitle}
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={9} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox component="ul" my={3} ml={6}>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training1}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training2}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training3}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training4}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training5}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.upcomingTraining1}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training6}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training7}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.upcomingTraining2}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training8}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training9}
                  </MKTypography>
                </MKBox>
                <MKBox component="li">
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    {Content.training10}
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Training;

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import footerRoutes from "footer.routes";

function Footer() {
  return (
    <MKBox component="footer" py={6} bgColor="light">
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={12}
            textAlign={{ xs: "center", lg: "left" }}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
          >
            <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
              Seattle, Washington - King County
              <br />
              helenathedoula@gmail.com
            </MKTypography>
            <Stack
              component="ul"
              direction="row"
              flexWrap="wrap"
              spacing={3}
              justifyContent={{ xs: "center", lg: "flex-start" }}
              pl={0}
              mb={3}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href={footerRoutes.menus.home.route}
                  rel="noreferrer"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {footerRoutes.menus.home.name}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href={footerRoutes.menus.about.route}
                  rel="noreferrer"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {footerRoutes.menus.about.name}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="https://doulamatch.net/profile/29010/helena-fonseca-fiorini-cd-pals"
                  target="_blank"
                  color="info"
                  rel="noreferrer"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },
                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  Doula match <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="https://www.instagram.com/doula_theshapeofwater/"
                  target="_blank"
                  rel="noreferrer"
                  variant="body2"
                  color="dark"
                  mt={0.4}
                  opacity={0.8}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i className="fab fa-instagram" />
                </MKTypography>
              </MKBox>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/helena.jpg";
import Content from "Content/about.json";
import Icon from "@mui/material/Icon";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: 0, md: 0 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Helena Fiorini" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={9} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Helena Fiorini</MKTypography>
                <MKBox>
                  <MKTypography
                    component="a"
                    href="https://doulamatch.net/profile/29010/helena-fonseca-fiorini-cd-pals"
                    variant="body2"
                    fontWeight="light"
                    target="_blank"
                    color="info"
                    mt={0}
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",

                      "& .material-icons-round": {
                        transform: `translateX(3px)`,
                        transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                      },

                      "&:hover .material-icons-round, &:focus .material-icons-round": {
                        transform: `translateX(6px)`,
                      },
                    }}
                  >
                    Doula match <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text" textAlign="justify">
                {Content.about1}
                <br />
                <br />
                {Content.about2}
                <br />
                <br />
                {Content.about3}
                <br />
                <br />
                {Content.about4}
                <br />
              </MKTypography>
              {/* <MKTypography variant="h2" fontWeight="light" color="text" textAlign="justify">
                {Content.about1}
                <br />
                <br />
                {Content.about2}
                <br />
                <br />
                {Content.about3}
                <br />
                <br />
                {Content.about4}
                <br />
              </MKTypography> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
